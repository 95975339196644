/**
 * @file   页头
 * @create zhangchen 2020/11/06
 * @update zhangchen 2020/11/12 修改一级栏目高亮bug
 * @update zhangchen 2020/11/16 解决useCallBack bug
 */

import React, { lazy, Suspense, useState, useMemo, useCallback, useRef, useEffect } from 'react';
import './index.scss';
import Loading from '@/app/components/Loading';
import { Nav as NavComp } from '@portal-site/components';
import { useRoutesMap } from '@portal-site/core';
import { NavLink } from 'react-router-dom';

const InnerBanner = lazy(() => import('./InnerBanner'));

// 栏目菜单
export function Nav() {
  const routes = useRoutesMap();

  // 检测当前路径是否为完整图片路径
  const isUrl = useCallback((src) => {
    const testReg = /^http[s]{0,1}:/;
    return testReg.test(src);
  }, []);
  const cl = useCallback(
    (route) => {
      if (route.url && isUrl(route.url)) {
        return (
          <a href={route.url} target="_blank" rel="noopener noreferrer">
            <div className="cn">{route.name}</div>
            <div className="en">{route.enChannelName}</div>
          </a>
        );
      }
      const isActive =
        route.url.split('/')[1] !== '' &&
        window.location.pathname.includes(route.url.split('/')[1]);
      return (
        <NavLink
          className={isActive ? 'active' : ''}
          to={route.url !== '/' && route.url ? route.url : route.path}
        >

          <div className="cn">{route.name}</div>
          <div className="en">{route.enChannelName}</div>
        </NavLink>
      );
    },
    [isUrl]
  );
  // 隐藏后台设置为不显示的栏目
  const exclude = useMemo(() => {
    const flat = routes.flat();
    return flat.filter((i) => !i.isVisible).map((i) => i.route);
  }, [routes]);
  return <NavComp routes={routes} exclude={exclude} renderNavLink={cl} />;
}

// 栏目菜单
const Headerbox = () => {
  const menu = useRef();
  const [active, setState] = useState(false);
  const routes = useRoutesMap();
  useEffect(() => {
    // 如果有值
    if (menu.current) {
      const li = menu.current.getElementsByClassName('portal-nav-firstmenu-li');
      for (let index = 0; index < li.length; index++) {
        // 当没有二级栏目时，不显示二级栏目背景条
        if (li[index].getElementsByTagName('li').length > 0) {
          li[index].onmouseover = function () {
            setState(true);
          };
          li[index].onmouseout = function () {
            setState(false);
          };
        } else {
          li[index].className = 'css-grjzz9 portal-nav-firstmenu-li nochild';
          li[index].onmouseover = function () {
            setState(false);
          };
        }
      }
    }
  }, [routes.children.length]);

  return (
    <div className="headerbox" ref={menu}>
      <a className="logo" href="/">
        <div className="logo-icon" />
      </a>
      <Nav />
      <ul className="quickmenu">
        {/* <li className="search">
          <Link to="/Search">
            <i className="iconfont icon-sousuo" />
            搜索
          </Link>
        </li> */}
      </ul>
      <div className={active ? 'secmenu-bg active' : 'secmenu-bg'} />
    </div>
  );
};

export default function (props) {
  const isIndex = window.location.pathname === '/' || window.location.pathname === '/Home';
  return (
    <>
      <header className="mainheader">
        <Headerbox isIndex={props.isIndex} />
      </header>
      {isIndex ? null : (
        <Suspense fallback={<Loading />}>
          <InnerBanner />
        </Suspense>
      )}
    </>
  );
}

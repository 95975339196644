import React, { useState } from 'react';
import MyLink from '../../../components/MyLink';
import cls from 'classnames'

export default function Last({ routes }) {
  const [activeindex, setActiveIndex] = useState(0)

  let lastarr = [
    {
      route: "Activities",
      path: '/Education/Activities',
    },
    {
      route: "ColDatabase",
      path: '/Collection/ColDatabase',
    },
    {
      route: "Lectures",
      path: '/Research/Lectures',
    },
    {
      route: "AcademicJournals",
      path: '/Research/AcademicJournals',
    },
    {
      route: "Cultural",
      path: '/Service/Cultural',
    },
    {
      route: "Video",
      path: '/News/Video',
    },
  ]
  return (
    <div className='last' >
      <div className="homecenter">
        {lastarr.map((item, index) => {
          return (
            <MyLink
              to={item.path}
              linkUrl={item.path ? '' : routes?.[item.route]?.url}
              className={cls("item", { active: activeindex === index })} key={index}

              onMouseMove={() => {
                setActiveIndex(index)
              }}
            >
              <div className="title">
                <div className="cn">{routes?.[item.route]?.name}
                </div>
                <div className="en">{routes?.[item.route]?.enChannelName}</div>
              </div>
            </MyLink>
          )
        })}
      </div>

    </div >
  )
}

/**
 * @file   文章详情
 * @create
 * @update lixiang 2020/09/02 增加文章来源
 */
import React, { useMemo } from 'react';
import { useQuery, useRoutesMap } from '@portal-site/core';
// import DetailShare from '../DetailShare';
import { useLocation } from 'react-router';
import cls from 'classnames';
import './index.scss';
// 详情
const MainDetail = (props) => {
  const { pathname } = useLocation();
  const { id } = props.match.params;
  const apitype = id ? 'queryArchivesById' : 'queryArchivesByNo';
  // const records = useQueryOne({ resource: id, params });

  // 有id则通过id获取详情，没有id通过最后一个栏目编号获取详情
  const res = id ? id : pathname.split('/')[pathname.split('/').length - 1];
  const { response = { records: [] } } = useQuery({
    api: apitype,
    options: res
  });
  const { records } = response;

  return <Detail id={id} data={records} pathname={pathname} channel={!id && res} />;
};

const Detail = (props) => {
  const isEn = window.isEn;
  const routes = useRoutesMap().flatToObject();
  /* if (Object.keys(props).length === 0) {
     return null;
   } */
  const { title, content, source, publishDate, visitCount, groupId } = props.data;
  // 获取附件
  const fileMemo = useMemo(
    () => ({
      pagination: {
        page: 0,
        size: 0
      },
      params: { groupId, code: 'CMS_ARCHIVES_FILE' }
    }),
    [groupId]
  );
  const { response = { records: [] } } = useQuery({
    api: 'queryFileList',
    options: fileMemo
  });
  const { records: filelist } = response;
  return (
    <div className={cls('maindetail', { maindetailbyno: !props.id })}>
      <div className="title">
        <h1>{!props.id ? routes?.[props?.channel]?.name : title}</h1>
      </div>
      {props.id ? (
        <>
          {/* 当来源字数过多时，分享换行显示 */}
          <div className={`tip ${source && source.length > 15 ? 'dbtip' : ''}`}>
            <div>
              <span>
                {isEn ? 'Publish Date' : '发布时间'}：{publishDate}
              </span>

              {source && (
                <span>
                  {isEn ? 'Source' : '来源'}：{source}
                </span>
              )}
              <span>
                {isEn ? 'Visit Count' : '浏览次数'}：{visitCount}
              </span>
            </div>
            {/* {!isEn && <DetailShare />} */}
          </div>
        </>
      ) : null}

      <div className="cont">
        <div dangerouslySetInnerHTML={{ __html: content }} />
        {/* 学术刊物显示附件中第一条pdf */}
        {props.pathname.indexOf('AcademicJournals') > -1
          ? filelist.length > 0 &&
            (filelist[0].type === 'pdf' ? (
              <iframe
                src={`/pdfjs/web/viewer.html?file=${filelist[0].filePath}`}
                title={filelist[0].filePath}
              />
            ) : null)
          : filelist &&
            filelist.length > 0 && (
              <div className="file-list">
                <p>附件：</p>
                <ul>
                  {filelist.map((item) => {
                    let el = (
                      <a
                        href={item.filePath}
                        download={item.fileOldname ? item.fileOldname.split('.')[0] : ''}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.fileOldname}
                      </a>
                    );
                    return <li key={item.id}>{el}</li>;
                  })}
                </ul>
              </div>
            )}
      </div>
    </div>
  );
};

export default MainDetail;

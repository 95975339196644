/**
 * @file   网站底部
 * @create macaiqi 2023/04/03
 * @update
 */
import React from 'react';
import './index.scss';
import wx from '@/assets/images/desktop/common/wx.jpg';
import wxsp from '@/assets/images/desktop/common/wxsp.jpg';
import wb from '@/assets/images/desktop/common/wb.jpg';
import dy from '@/assets/images/desktop/common/dy.jpg';
// import wx1 from '@/assets/images/hp/common/wx1.jpg';
import { Nav } from '../Header';
// 第一部分 网站地图
const Firstpart = () => {
  return (
    <div className="firstpart">
      <div className="allcenter flex">
        <Nav />
        <div className="r">
          <div className="r1">
            <div className="box">
              <i />
              微信公众号
              <img src={wx} alt="" />
            </div>
            <div className="box">
              <i />
              微信视频号
              <img src={wxsp} alt="" />
            </div>
            <div className="box">
              <i />
              关注微博
              <img src={wb} alt="" />
            </div>
            <div className="box">
              <i />
              关注抖音
              <img src={dy} alt="" />
            </div>
          </div>
          <div className="address">
            <p>地址：四川省宜宾市翠屏区沙坪街道长翠路宜宾市博物院</p>
            <p>电话：0831-3663222</p>
          </div>

        </div>
      </div>
    </div>
  );
};
function Footer(props) {
  const isEn = window.isEn;
  const year = new Date().getFullYear();

  return (
    <footer className="mainfooter">
      <Firstpart isEn={isEn} />

      <div className="secpart">
        <div className="allcenter txt">
          <span>
            版权所有：中国·宜宾博物院&nbsp;{year}&nbsp;
            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">蜀ICP备2021015026号</a>
            {/* 川公网安备 51099002000109号 */}
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

/**
 * @file   概况-简介
 * @create lixiang 2023/04/20
 * @update
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@portal-site/core';
import { Picture } from '@portal-site/components';
import Viewer from 'viewerjs';
import './index.scss';
const FileList = ({ groupId }) => {
  const fileMemo = useMemo(
    () => ({
      pagination: {
        page: 0,
        size: 0
      },
      params: { groupId, code: 'CMS_ARCHIVES_FILE' }
    }),
    [groupId]
  );
  const { response = { records: [] } } = useQuery({
    api: 'queryFileList',
    options: fileMemo
  });
  const { records } = response;
  const [viewer, setViewer] = useState(null);
  const v = useRef(null);
  // 点击
  const handleClick = useCallback(
    (index) => {
      viewer.view(index);
    },
    [viewer]
  );
  useEffect(() => {
    if (records?.[0] && v.current) {
      if (!viewer || (viewer && viewer.images && viewer.images.length === 0)) {
        setViewer(
          new Viewer(v.current, {
            inline: false,
            url: 'data-original',
            title: 0
          })
        );
      }
    }
    return () => {
      viewer && viewer.destroy();
    };
  }, [records, viewer]);
  return (
    <>
      <ul className="introfilelist">
        {records.map((item, index) => {
          return (
            <li key={item.id}>
              <Picture
                source={item.filePath}
                onClick={() => {
                  handleClick(index);
                }}
              />
            </li>
          );
        })}
      </ul>
      <div ref={v} style={{ display: 'none' }}>
        {records[0] &&
          records.map((item) => {
            const { id = '', title, filePath } = item;
            return (
              <img
                src={filePath ? filePath : ''}
                key={id}
                alt={title}
                data-original={filePath ? filePath : ''}
              />
            );
          })}
      </div>
    </>
  );
};

function Introduction(props) {
  const assetsPrefix = window.ENV_IMG_PREURL;
  const { response = { records: [] } } = useQuery({
    api: 'queryArchivesByNo',
    options: 'Introduction'
  });
  const { records } = response;
  return (
    records && (
      <div className="introwrap">
        <div className="allcenter">
          <div className="contbox">
            <div className="cont" dangerouslySetInnerHTML={{ __html: records.content }} />
            <div className="imgbox">
              <img src={`${assetsPrefix}${records.imgSrc}`} alt="" />
            </div>
          </div>
        </div>

        {records.groupId && <FileList groupId={records.groupId} />}
      </div>
    )
  );
}

export default Introduction;

/**
 * @file   首页
 * @create macaiqi 2021/07/27
 * @update
 */
import React from 'react';
import Banner from './Banner';
import News from './News';
import OpenTime from './OpenTime';
import Exhibition from './Exhibition';
import Collection from './Collection';
import Last from './Last';
import { useRoutesMap } from '@portal-site/core';
import './index.scss';
import { Link } from 'react-router-dom';

// 首页
const IndexPage = () => {
  const routes = useRoutesMap().flatToObject();

  return (
    <div className="home-page">
      <div className="index-banner">
        <Banner />
      </div>
      <div className="home-cont">
        <div className="section">
          <h3 className="welcome">
            <div className="cn">欢迎参观宜宾市博物院</div>
            <div className="en">Welcome to Yibin Museum</div>
          </h3>
          <div className="homecenter">
            <div className="f">
              <News routes={routes} />
              <OpenTime routes={routes} />
              <div className="moreinfo">
                <Link to="/Service/Visit" className="box">
                  <span className="cn">{routes?.['Visit']?.name}</span>
                  <span className="en">{routes?.['Visit']?.enChannelName}</span>
                </Link>
                <Link to="/Service/Explain" className="box">
                  <span className="cn">{routes?.['Explain']?.name}</span>
                  <span className="en">{routes?.['Explain']?.enChannelName}</span>
                </Link>
                <Link to="/Service/Cultural" className="box">
                  <span className="cn">{routes?.['Cultural']?.name}</span>
                  <span className="en">{routes?.['Cultural']?.enChannelName}</span>
                </Link>
                <Link to="/Service/Appointment" className="box">
                  <span className="cn">{routes?.['Appointment']?.name}</span>
                  <span className="en">{routes?.['Appointment']?.enChannelName}</span>
                </Link>

              </div>
            </div>
          </div>
        </div>
        <div className="section1">
          <Exhibition routes={routes} />
        </div>
        <div className="vr">
          <Link to="/Exhibition/VirtualExhibition">
            <span className="cn">
              {routes?.['VirtualExhibition']?.name}
              <span className="en">{routes?.['VirtualExhibition']?.enChannelName}</span>
            </span>
          </Link>
        </div>

        <Collection routes={routes} />
        <Last routes={routes} />
      </div>
    </div>
  );
};

export default IndexPage;

import { useMemo } from 'react';
import { useQuery } from '@portal-site/core';
import { useParams } from 'react-router';

const useColDetail = function () {
  const { id, groupId } = useParams();
  const options = useMemo(() => {
    return {
      pagination: {
        size: 0,
        page: 0
      },
      params: {
        groupId: groupId,
        id: id,
        code: 'CMS_EXHIBIT_INFO_DISPLAY'
      }
    };
  }, [id, groupId]);
  const { response = [{ records: [] }, { records: {} }], loading } = useQuery({
    api: 'queryColDetail',
    options: options
  });

  const [{ records: list }, { records: detail }] = response;

  return {
    detail,
    list,
    loading
  };
};

export default useColDetail;

/**
 * @file   常设展览
 * @create macaiqi 2023/03/30
 * @update
 */
import React, { useMemo, useState } from 'react';
import { useQuery } from '@portal-site/core';
import { Picture } from '@portal-site/components';
import { Link } from 'react-router-dom';

export default function Exhibition({ routes }) {
  const [showindex, setshowindex] = useState(0); // 显示的索引值
  let size = 12;
  const opt = useMemo(() => {
    return {
      api: 'queryExhibitionBaseList',
      options: {
        pagination: { page: 1, size },
        params: {
          // linkUrl: '1',
        }
      }
    };
  }, [size]);
  const { response: response1 = { records: [], pages: 1, total: 1 } } = useQuery(opt);
  const { records, total } = response1;
  return (
    <div className="exhibition ">
      <div className="homecenter">
        <div className="bigtitle big1">
          <h3>
            <div className="cn">{routes?.['BaseExhibition']?.name}</div>
            <div className="en">{routes?.['BaseExhibition']?.enChannelName}</div>
          </h3>
        </div>
        <div className="exh-list">
          {records?.slice(showindex, showindex + 2)?.map((item, index) => {
            return (
              <div className="item" key={item.id}>
                <Link to={`/Exhibition/BaseExhibition/${item.id}`}>
                  <Picture source={item?.imgSrc} />
                  <div className="t">
                    {item?.title?.includes('——') ? (
                      <>
                        <span>{item?.title?.split('——')?.[0]}</span>
                        <span> ——{item?.title?.split('——')?.[1]}</span>
                      </>
                    ) : (
                      item?.title
                    )}
                  </div>
                </Link>
              </div>
            );
          })}
          <div
            className="next"
            onClick={() => {
              if (total > showindex + 2) {
                setshowindex(showindex + 1);
              } else {
                setshowindex(0);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

/**
 * @file   藏品详情
 * @update zhangchen 2020/11/20 修改大图显示bug
 */
import { Picture } from '@portal-site/components';
import cls from 'classnames';
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import Slider from 'react-slick';
import Viewer from 'viewerjs';
import { useRoutesMap } from '@portal-site/core';
import './style.scss';
import { changePictureSize } from '../../../../../utils';
// 滑块箭头
const Arrow = function(props) {
  const { className, onClick, iconName } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className={`iconfont ${iconName}`} />
    </div>
  );
};

const usePictureSlide = function(list = [], loading) {
  const routes = useRoutesMap().flatToObject();
  const [active, setActive] = useState({});
  const [viewer, setViewer] = useState(null);
  // slider 参数
  const settings = useMemo(
    () => ({
      slidesToShow: 3,
      dots: false,
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToScroll: 3,
      nextArrow: <Arrow iconName="icon-xiangyou" />,
      prevArrow: <Arrow iconName="icon-xiangzuo" />
    }),
    []
  );
  const v = useRef(null);
  // 点击
  const handleClick = useCallback(() => {
    viewer.view(list.indexOf(active));
  }, [list, viewer, active]);

  useEffect(() => {
    if (list[0].id && Object.keys(active).length === 0) {
      setActive(list[0]);
    }
    if (!loading && v.current) {
      if (!viewer || (viewer && viewer.images && viewer.images.length === 0)) {
        setViewer(
          new Viewer(v.current, {
            inline: false,
            url: 'data-original',
            title: [4, (image) => `${image.alt} `]
          })
        );
      }
    }
    return () => {
      viewer && viewer.destroy();
    };
  }, [list, active, loading, viewer]);
  const element = (
    <div className="use-picture-slider-box">
      <Picture source={changePictureSize(active.filePath, 600)} base="" />
      {list.length > 1 ? (
        <Slider {...settings} key={list.length}>
          {list.map((item) => {
            const { id = '', title, filePath } = item;
            return (
              <div className={cls('imgstyle use-img full', { active: active.id === id })} key={id}>
                <img
                  onClick={() => setActive(item)}
                  src={filePath ? changePictureSize(filePath, 600) : ''}
                  alt={title}
                />
              </div>
            );
          })}
        </Slider>
      ) : null}
      <div className="topi">
        {routes?.['TPXZ']?.isVisible && (
          <i
            className="iconfont icon-xiazai"
            onClick={() => {
              const x = new XMLHttpRequest();
              const resourceUrl = active.filePath;
              x.open('GET', resourceUrl, true);
              x.responseType = 'blob';
              x.onload = function() {
                const urls = window.URL.createObjectURL(x.response);
                const a = document.createElement('a');
                a.href = urls;
                a.download = active.title + '.png';
                a.click();
              };
              x.send();
            }}
          />
        )}
        {routes?.['TPFD']?.isVisible && (
          <i className="iconfont iconfangdajing" onClick={handleClick} />
        )}
      </div>

      <div ref={v} style={{ display: 'none' }}>
        {list[0].id &&
          list.map((item) => {
            const { id = '', title, filePath } = item;
            return (
              <img
                src={filePath ? changePictureSize(filePath, 300) : ''}
                key={id}
                alt={title}
                data-original={filePath ? filePath : ''}
              />
            );
          })}
      </div>
    </div>
  );
  return element;
};

export default usePictureSlide;

import React, { PureComponent } from 'react';
import { getInitPage, parseSearch } from '../../../utils/platform';
export default function withScrollTo(WrappedComp, anchor) {
  return class extends PureComponent {
    state = {
      page: getInitPage(parseSearch(this.props.location.search))
    };
    static getDerivedStateFromProps(props, state) {
      const { search } = props.location;
      const page = parseSearch(search).get('page');
      if (typeof page !== 'undefined' && Number(page) !== state.page) {
        return {
          page: Number(page)
        };
      }
      return null;
    }
    componentDidMount() {
      this.setScrollTop();
    }
    componentDidUpdate(prevProps, prevState) {
      if (this.state.page !== prevState.page) {
        this.setScrollTop();
      }
    }
    setScrollTop() {
      let anchorDom = null;
      let top = 0;
      const anchorType = typeof anchor;
      if (anchorType === 'object') {
        anchorDom = anchor;
      } else if (anchorType === 'string') {
        anchorDom = document.querySelector(anchor);
      } else if (anchorType === 'number') {
        top = anchor;
      }

      if (anchorDom !== null) {
        // 距离顶部的距离
        const t = anchorDom.getBoundingClientRect().top + window.scrollY;
        document.body.getBoundingClientRect().height - window.innerHeight < top
          ? (top = 0)
          : (top = t);
      }
      try {
        top === 0 ? window.scrollTo(0, 0) : window.scrollTo({ top: top, behavior: 'smooth' });
      } catch (error) {
        window.scrollTo(0, top);
      }
    }
    render() {
      return <WrappedComp {...this.props} anchor={anchor} />;
    }
  };
}

/**
 * @file   首页轮播图
 * @create macaiqi 2023/03/27
 * @update
 */
import React, { useMemo } from 'react';
import { Picture } from '@portal-site/components';
import { useQuery } from '@portal-site/core';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

export default function Banner() {
  const option = useMemo(() => {
    return {
      api: 'queryArchivesList',
      options: {
        resource: 'Slide',
        pagination: {
          page: 0
        }
      }
    };
  }, []);
  // get data
  const { response = {} } = useQuery(option);
  const { records } = response;

  // 滑块箭头
  const Arrow = function(props) {
    const { className, onClick, iconName } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className={`iconfont ${iconName}`} />
      </div>
    );
  };
  const settings = {
    arrows: true,
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    autoplaySpeed: 5000,
    nextArrow: <Arrow iconName="icon-you" />,
    prevArrow: <Arrow iconName="icon-zuo" />
  };

  return (
    <Slider {...settings}>
      {records &&
        records.map((item, index) => {
          return item.linkUrl ? (
            item.linkUrl.indexOf('http') !== -1 || item.linkUrl.indexOf('https') !== -1 ? (
              <a key={item.id} href={item.linkUrl} target="_blank" rel="noreferrer">
                <Picture source={item.imgSrc} />
              </a>
            ) : (
              <Link to={item.linkUrl} target="_blank" key={item.id}>
                <Picture source={item.imgSrc} />
              </Link>
            )
          ) : (
            <Picture source={item.imgSrc} key={item.id} />
          );
        })}
    </Slider>
  );
}

import React from 'react';
import cls from 'classnames';
import './index.scss';
const Loading = function ({ isMobile = false, minHeight = '500px' } = {}) {
  return (
    <div
      className={cls('lds-ellipsis-box', { mobile: isMobile })}
      style={{
        height: minHeight,
        lineHeight: minHeight
      }}
    >
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
export default Loading;

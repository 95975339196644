import React, { useMemo } from 'react';
import { Skeleton } from '@portal-site/components';
import useColDetail from './container/useColDetail';
import usePictureSlide from './container/usePictureSlide';
// import Relate from '../../common/proRelate';
// import { useLocation } from 'react-router-dom';

const assetsPrefix = window.ENV_IMG_PREURL;
/**
 * @file   藏品详情
 * @create xuqingqing 2020/09/09
 * @update xuqingqing 2020/09/09
 */

const ColDetail = function() {
  // 数据
  const { detail, list, loading } = useColDetail();
  const { id, title, content, age, ageDetail, level, size, imgSrc, modelUrl } = detail;
  // usePictureSlide
  const ll = useMemo(() => {
    return [
      {
        id: id,
        title,
        filePath: assetsPrefix + imgSrc
      },
      ...list
    ];
  }, [id, title, list, imgSrc]);
  const element = usePictureSlide(ll, loading);

  return (
    <Skeleton loading={loading} render={() => <Skeleton.Paragraph rows={10} />}>
      <div className="collect-detail">
        <div className="title">
          <h1>
            {title}
            {modelUrl && (
              <a href={modelUrl} className="threed" target="_blank" rel="noreferrer">
                <i className="iconfont icon-threed" />
              </a>
            )}
          </h1>
        </div>
        <div className="cont-box">
          <div className="left">{element}</div>
          <div className="tips">
            <div className="top">
              {(age || ageDetail) && (
                <div className="p">
                  <span>年代</span>
                  <p>{age || ageDetail}</p>
                </div>
              )}
              {level && (
                <div className="p">
                  <span>级别</span>
                  <p>{level}</p>
                </div>
              )}
              {size && (
                <div className="p">
                  <span>尺寸</span>
                  <p>{size}</p>
                </div>
              )}
              {/* <div className="p">
                <span>收藏单位</span>
                <p>{collectionUnit}</p>
              </div> */}
            </div>
          </div>
        </div>
        {content && (
          <>
            <h3 className="sec-title">简介：</h3>
            <div className="cont" dangerouslySetInnerHTML={{ __html: content }} />
          </>
        )}
        {/* {label && label.length > 0 && channelArr && (
          <Relate label={label} id={id} channelArr={channelArr} />
        )} */}
      </div>
    </Skeleton>
  );
};

export default ColDetail;

/**
 * @file   信息资讯
 * @create macaiqi 2023/03/27
 * @update
 */
import React, { useMemo } from 'react';
import { useQuery } from '@portal-site/core';
import dayjs from 'dayjs';
import { htmltoStr } from '@/utils';
import { Link } from 'react-router-dom';

export default function News({ routes }) {
  const options = useMemo(() => {
    return {
      api: 'queryArchivesList',
      options: {
        resource: 'SNews',
        pagination: { page: 1, size: 3 }
      }
    };
  }, []);
  const { response = { records: [] } } = useQuery(options);
  const { records } = response;
  return (
    <div className="news">
      <div className="bigtitle">
        <h3>
          <div className="cn">{routes?.["SNews"]?.name}</div>
          <div className="en">{routes?.["SNews"]?.enChannelName}</div>
        </h3>
        <Link to="/News/SNews">更多 &gt;</Link>
      </div>
      <ul className="news-list">
        {records.map((item, i) => {
          return (
            <li key={item.id}>
              <Link to={`/News/${item.channelNo}/${item.id}`}>
                <div className="time">
                  <p className="time1">{dayjs(item.publishDate).format('DD')}</p>
                  <p className="time2">{dayjs(item.publishDate).format('YYYY-MM')}</p>
                </div>
                <div className="r">
                  <h3>{item.title}</h3>
                  <p>{htmltoStr(item.content, 70)}</p>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

/**
 * @file   馆藏精品
 * @create macaiqi 2023/03/31
 * @update
 */
import React, { useState, useMemo } from 'react';
import { useQuery } from '@portal-site/core';
import { Link } from 'react-router-dom';
import { Picture } from '@portal-site/components';
import Slider from "react-slick";

export default function Collection({ routes }) {
  // const [slideindex, setSlideindex] = useState(0)
  const [code, setCode] = useState('taociqi')

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    centerPadding: "0",
    // variableWidth: true,
    focusOnSelect: true,
    beforeChange: (current, next) => {
      // setSlideindex(next)
      setCode(routes?.['HomeCollection']?.children[next <= 6 ? next : next - 7].route)
    },
    // nextArrow: <Arrow iconName="icon-xiangyou" />,
    // prevArrow: <Arrow iconName="icon-xiangzuo" />
  };

  // 链接地址录在首页藏品里面了
  const options = useMemo(() => {
    return {
      api: 'queryArchivesList',
      options: {
        resource: code,
        pagination: { page: 0, size: 0 }
      }
    };
  }, [code]);
  const { response = { records: [] } } = useQuery(options);
  const { records } = response;


  return (
    <div className='collection'>
      <div className="homecenter">
        <div className="bigtitle big1">
          <h3>
            <div className="cn">{routes?.["Treasure"]?.name}</div>
            <div className="en">{routes?.["Treasure"]?.enChannelName}</div>
          </h3>
        </div>
        <Slider {...settings} className='col-slider'>
          {routes?.['HomeCollection']?.children?.map((item, index) => {
            return (
              <div key={index} className='item'>
                <div className="txt">
                  {item.name?.length > 3 ?
                    <>
                      <span>{item.name?.slice(2, 4)}</span>
                      <span>{item.name?.slice(0, 2)}</span>
                    </>

                    : <span>{item.name}</span>}

                </div>
              </div>
            )
          })}
          {routes?.['HomeCollection']?.children?.map((item, index) => {
            return (
              <div key={index} className='item'>
                <div className="txt">
                  {item.name?.length > 3 ?
                    <>
                      <span>{item.name?.slice(2, 4)}</span>
                      <span>{item.name?.slice(0, 2)}</span>
                    </>

                    : <span>{item.name}</span>}

                </div>
              </div>
            )
          })}
        </Slider>
        <div className="col-box">
          {records?.map((item) => {
            let imgstyle = {}
            for (let j = 0; j < item.extension1?.split(',')?.length; j++) {
              let item1 = item.extension1?.split(',')[j]
              imgstyle[item1?.split(":")?.[0]] = item1?.split(":")?.[1]
            }


            return (
              <Link to={item?.linkUrl} target='_blank' className={`item `} key={item.id} style={imgstyle}>
                {/* {item.title !== '唐敦煌写本《妙法莲华经》' && <div className="t" style={titlestyle}>
                  {item.title}
                </div>} */}
                <Picture source={item.imgSrc} className='normal' />
                <Picture source={item.appImgSrc} className='active' />
              </Link>
            )
          })
          }
        </div>
      </div>

    </div>
  )
}

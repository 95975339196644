/**
 * @file   开发时间
 * @create macaiqi 2023/03/30
 * @update
 */
import React from 'react';

export default function OpenTime({ routes }) {
  return (
    <div className="opentime">
      <div className="bigtitle">
        <h3>
          <div className="cn">开放时间</div>
          <div className="en">Opening Hours</div>
        </h3>
      </div>
      <div className="time">
        <div className="i">
          <p className="t">9:00</p>
          <p className="d">入馆时间</p>
          <i>-</i>
        </div>
        <div className="i">
          <p className="t">16:30</p>
          <p className="d">停止入馆</p>
          <i>-</i>
        </div>
        <div className="i">
          <p className="t">17:00</p>
          <p className="d">闭馆时间</p>
        </div>
      </div>
      <div className="tips">
        <p>周二至周日开放参观，周一闭馆维护</p>
        <p>（法定节假日除外）</p>
      </div>
      <div className="linenew" />
      <div className="tipsnew">
        <div className="sectitle">【个人参观】</div>
        <p>宜宾市博物院现已实行免预约参观，观众可直接到馆，接受安全检查后方可入馆参观。</p>
      </div>
      <div className="tipsnew">
        <div className="sectitle">【团队预约】</div>
        <p>团队参观请提前预约，预约咨询电话：0831-3663222</p>
      </div>
      {/* <div className="line" />
      <div className="tips1">
        <p>参观前请自觉接受安全检查</p>
        <p>
          入馆前，观众请在官微（微信号：yb-bwy）进行预约；
          预约成功后凭身份证入馆参观，或至服务台进行人脸识别登记入馆参观。
        </p>
        <p>
          团队参观请提前预约
          <br />
          预约咨询电话：0831-3663222
        </p>
      </div> */}
    </div>
  );
}
